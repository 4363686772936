import { Homepage } from "./pages";

function App() {
  return (
    <div style={{background:'#000000'}}>
      <Homepage />
    </div>
  );
}

export default App;
